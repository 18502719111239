import React, { Fragment, useEffect } from 'react'
import AppHeader from '../../Layout/AppHeader'
import AppSidebar from '../../Layout/AppSidebar'
import AppFooter from '../../Layout/AppFooter'

import { ReactCSSTransitionGroup, PageTitle } from '../../Components'
import { Card, CardBody, Col, Row } from 'reactstrap'
import Lottie from 'react-lottie'
import animationData from './welcome.json'
import { getUser } from '../../Reducers/storageApp'

import { getToken } from 'firebase/messaging'
import { messaging } from '../../Service/firebaseConfig'
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
}

export default function Dashboards() {
  const user = getUser()

  async function requestPermission() {
    const permission = await Notification.requestPermission()

    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: 'BAaZ94f3A6SpKYfHggUF9omsIE2C1vu6Z0VRNeTSrohgFZ5hDALuC0dGstHZks_d0ZUK9bZqM3dhOmKWa7UtQs0',
      })

      console.log('Token generated : ', token)
    }
  }

  useEffect(() => {
    requestPermission()
  }, [])

  return (
    <>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
              <div className="body-tabs body-tabs-layout">
                <PageTitle heading="Página Inicial" subheading="Resumo das operações no portal" tpIcon="Bs" icon="BsGridFill" />
                <div className="page-content">
                  {!user?.contaId && <li className="list-group-item-danger list-group-item mb-2">Selecione uma conta para habilitar mais funcionalidades</li>}

                  <div style={{ margin: 'auto 0', width: '100%' }}>
                    <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
                      <Card className="main-card mb-2">
                        <CardBody style={{ textAlign: 'center' }}>
                          <Row>
                            <Col md={12}>
                              <h1> Seja bem-vindo(a) ao MikroBit</h1>
                            </Col>
                            <Col md={12}>
                              <Lottie options={defaultOptions} style={{ maxHeight: '400px', maxWidth: '600px' }} />
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </ReactCSSTransitionGroup>
                  </div>

                  {/*<Row>
                     <Col lg="12">
                      <Card className="main-card mb-2">
                        <CardBody>
                          <CardTitle>AVALIAÇÕES DE PRODUTOS PENDENTES DE APROVAÇÃO</CardTitle>
                          <PendingReviews />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="6">
                      <Card className="main-card mb-2">
                        <CardBody>
                          <CardTitle>PEDIDOS RECENTES DE CLIENTES</CardTitle>
                          <CardOrders />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="6">
                      <Card className="main-card mb-2">
                        <CardBody>
                          <CardTitle>PRODUTOS COM ESTOQUE BAIXO</CardTitle>
                          <LowStock />
                        </CardBody>
                      </Card>
                    </Col> 
                  </Row>*/}
                </div>
              </div>
            </ReactCSSTransitionGroup>
          </div>
          <AppFooter />
        </div>
      </div>
    </>
  )
}
