import React from 'react'
import MetisMenu from 'react-metismenu'
import { NavNotAccount, NavCliAdmin, NavAdmin, NavCliRep } from './NavItems'
import { getUser } from '../../Reducers/storageApp'

export default function AppNav() {
  const { contaId, perfil } = getUser()

  function getMenu() {
    if (contaId === 1) return NavAdmin
    if (perfil === 'A') return NavCliAdmin
    if (perfil === 'R') return NavCliRep

    return NavNotAccount
  }
  let menuRender
  menuRender = (
    <>
      <MetisMenu content={getMenu()} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="fa fa-angle-down" />
    </>
  )
  return <>{menuRender}</>
}
