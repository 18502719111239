import React from 'react'
import { Label, Tooltip } from 'reactstrap'

class TooltipItem extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      tooltipOpen: false,
    }
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    })
  }

  render() {
    return (
      <>
        {this.props.children ? (
          this.props.children
        ) : (
          <Label style={{ marginLeft: '5px', height: '18px' }} className="badge badge-pill badge-secondary" color="primary" id={this.props.id}>
            {this.props.text}
          </Label>
        )}

        <Tooltip className="tooltip-light" placement={this.props.placement} isOpen={this.state.tooltipOpen} target={this.props.id} toggle={this.toggle}>
          {this.props.content}
        </Tooltip>
      </>
    )
  }
}

export default TooltipItem
