export const NavNotAccount = [{ icon: 'fa fa-list', label: 'Página Inicial', to: '/#/' }]

export const NavCliAdmin = [
  { icon: 'fa fa-chart-pie', label: 'Dashboard', to: '/#/Dashboard' },

  { icon: 'fa fa-users', label: 'Clientes', to: '/#/Clients' },
  { icon: 'fa fa-commenting-o', label: 'Chat', to: '/#/Chat' },

  { icon: 'fa fa-briefcase', label: 'CRM', to: '/#/CRM/Funnel' },

  { icon: 'fa fa-bullseye', label: 'Campanhas', to: '/#/Campaign' },
  { icon: 'fa-solid fa-chart-gantt', label: 'Projetos', to: '/#/Project' },
  { icon: 'fa-regular fa-circle-check', label: 'Tarefas', to: '/#/Tasks' },

  { icon: 'fa fa-gear', label: 'Configurações', to: '/#/Settings' },

  {
    icon: 'fa fa-chart-bar',
    label: 'Relatórios',
    content: [
      { label: 'Leads', to: '/#/Reports/Leads' },
      { label: 'Timesheet', to: '/#/Reports/Timesheet' },
    ],
  },
]

export const NavCliRep = [
  { icon: 'fa fa-list', label: 'Home', to: '/#/' },
  { icon: 'fa fa-commenting-o', label: 'Chat', to: '/#/Chat' },
  { icon: 'fa-regular fa-circle-check', label: 'Tarefas', to: '/#/Tasks' },
]

export const NavAdmin = [
  { icon: 'fa fa-list', label: 'Home', to: '/#/' },
  {
    icon: 'fa fa-gear',
    label: 'Configurações',
    content: [
      { label: 'Contas', to: '/#/Settings/Accounts' },
      { label: 'Parâmetros', to: '/#/Settings/Parameters' },
    ],
  },
]
