import { decode, encode } from 'base-64'

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state')
    if (!serializedState) {
      return {
        token: {
          value: '',
          expiration: '',
        },
        user: {
          id: '',
          name: '',
          perfil: '',
          contaId: '',
        },
        userLogState: '',
      }
    } else {
      return JSON.parse(serializedState)
      //Prod
      // var atob = require('atob')
      // let decoded = atob(serializedState)
      // var stateObj = JSON.parse(decoded)
      // return stateObj
    }
  } catch (err) {
    return undefined
  }
}

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('state', serializedState)
    //Prod
    // let encoded = btoa(serializedState)
    // localStorage.setItem('state', encoded)
  } catch (err) {
    console.error(err)
  }
}

export const getToken = () => {
  try {
    var state = loadState()
    const token = state.token

    var moment = require('moment')
    if (!token?.expiration || new Date(token?.expiration) < moment.utc()) {
      putToken()
      return undefined
    }
    return token.value
  } catch {
    return undefined
  }
}
export const putToken = (token, expiration) => {
  try {
    var state = loadState()
    if (token) state = { ...state, token: { value: token, expiration } }
    else state = { ...state, token: undefined }
    saveState(state)
  } catch {}
}

export const getUser = () => {
  try {
    var state = loadState()
    return state.user
  } catch {
    return undefined
  }
}
export const putUser = (values) => {
  try {
    var state = loadState()
    if (values) state = { ...state, user: { ...state.user, ...values } }
    else state = { ...state, user: undefined }
    saveState(state)
  } catch {}
}
export const getUserLogState = () => {
  try {
    var state = loadState()
    let result = undefined
    if (state.userLogState) {
      result = decode(state.userLogState)
    }
    return result
  } catch (error) {
    console.error('getUserLogState', error)
    return undefined
  }
}
export const putUserLogState = (value) => {
  try {
    var state = loadState()
    if (value) state = { ...state, userLogState: encode(value) }
    else state = { ...state, user: undefined }
    saveState(state)
  } catch {}
}
